import React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';

const Standard = ({data, pageContext: {description, keywords, title, url}}) => {
    const {
        frontmatter: {
            lang,
            name,
            path,
        },
        html,
    } = data.markdownRemark;

    return (
        <Layout
            description={description}
            keywords={keywords}
            lang={lang}
            name={name}
            path={path}
            title={title}
            url={url}
        >
            <div dangerouslySetInnerHTML={{__html: html}} />
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
            frontmatter {
                name
                lang
                path
            }
            html
        }
    }
`;

export default Standard;
